<template>
  <div class="stepBox">
    <ul class="step-list">
      <li
        v-for="(item, index) in StepList"
        :key="index"
        class="step-items"
        :class="{'old-step': item.flag === 1, 'now-step': item.flag === 2}"
        :style="{cursor: item.hit === 1 ? 'pointer' : 'default'}"
        @click="clickStep(item)"
      >
        <span v-if="index === 0">提交订单</span>
        <span v-if="index === 1">付款成功</span>
        <span v-if="index === 2">分拣订单</span>
        <span v-if="index === 3">商品出库</span>
        <span v-if="index === 4">商品运输</span>
        <span v-if="index === 5">商品送达</span>
        <span>{{ item.time }}</span>
      </li>
      <!-- <li class="step-items old-step">
        <span>付款成功</span>
        <span>2021-07-21 13:56:12</span>
      </li>
      <li class="step-items old-step">
        <span>分拣订单</span>
        <span>2021-07-21 13:56:12</span>
      </li>
      <li class="step-items old-step">
        <span>商品出库</span>
        <span>2021-07-21 13:56:12</span>
      </li>
      <li class="step-items now-step">
        <span>商品运输</span>
        <span>2021-07-21 13:56:12</span>
      </li>
      <li class="step-items">
        <span>商品送达</span>
        <span>2021-07-21 13:56:12</span>
      </li> -->
    </ul>
  </div>
</template>

<script>
export default {
  name: 'StepBox',
  components: {
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  emits: ['step'],
  computed: {
    StepList() {
      return this.list
    }
  },
  methods: {
    clickStep(item) {
      if (item.hit === 1) {
        this.$emit('step', item)
      }
    }
  }
}
</script>
<style lang='less' scoped>
.stepBox {
  padding: 32px 0;

  .step-list {
    display: flex;
    align-items: center;
  }

  .step-items {
    width: 220px;
    height: 72px;
    background-color: #F8F8F8;
    text-align: center;
    padding: 16px;
    color: #666666;
    // cursor: pointer;
    &>span {
      display: block;
      font-weight: 400;

    }
  }
  .old-step {
    background-color: #B0C2B9;
    color: #fff;
  }
  .now-step {
    background-color: #3B6652;
    color: #fff;
  }
}
</style>
