<template>
  <div>
    <a-drawer
      v-model:visible="showLigistics"
      placement="right"
      :closable="true"
      width="528"
      @close="clossFun"
    >
      <div class="Logistics">
        <div class="Logistics-info">
          <div class="Logistics-info-title db-mb24">物流信息</div>
          <div class="Logistics-info-item db-mb16">
            <span class="db-mr16">订单编号</span>
            <span>{{ Logistics.parcelSn }}</span>
          </div>
          <div class="Logistics-info-item db-mb16">
            <span class="db-mr16">承运公司</span>
            <span>{{ Logistics.deliveryCompany || '' }}</span>
          </div>
          <div class="Logistics-info-item db-mb16">
            <span class="db-mr16">运单编号</span>
            <span>{{ Logistics.deliverySn }}</span>
          </div>
          <div class="Logistics-info-item db-mb16">
            <span class="db-mr16">发出时间</span>
            <span>{{ Logistics.deliveryTime }}</span>
          </div>
        </div>

        <div class="Logistics-list">
          <div class="Logistics-info-title db-mb24">订单轨迹</div>
          <a-spin :spinning="isLoading">
            <a-timeline>
              <a-timeline-item v-for="(item, index) in info.Traces" :key="index">
                <template #dot><img src="@/assets/images/icons/radio-select.png" alt="raido"></template>
                {{ item.AcceptStation }}<br>{{ item.AcceptTime }}<br>{{ item.Remark }}
              </a-timeline-item>
              <!-- <a-timeline-item>
                <template #dot><img src="@/assets/images/icons/radio-select.png" alt="raido"></template>
                Technical testing 2015-09-01
              </a-timeline-item>
                <template #dot><img src="@/assets/images/icons/radio-select.png" alt="raido"></template>
                Technical testing 2015-09-01
              </a-timeline-item> -->
            </a-timeline>
          </a-spin>
        </div>
      </div>

    </a-drawer>
  </div>
</template>

<script>
import { getLogistics } from '@/api/order'
import logisticsList from '@/assets/json/logistics.json'
import { mapGetters } from 'vuex'
export default {
  name: 'Logistics',
  components: {
  },
  props: {
    drawerLigistics: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closeDrawer'],
  data() {
    return {
      showLigistics: false,
      info: {},
      showitem: [],
      Logistics: {},
      logisticsList
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'loading/isLoading'
    })
  },
  watch: {
    'drawerLigistics': {
      handler(val, oval) {
        this.showLigistics = val
      },
      deep: true
    }
  },
  methods: {
    clossFun() {
      this.$emit('closeDrawer', false)
    },
    init(detailData) {
      // console.log(detailData, 'logisticsList')
      this.Logistics = detailData
      this.info = {}
      this.showitem = logisticsList.filter(item => item.comCode === detailData.deliveryCompany)
      if (this.showitem.length && detailData.deliverySn) {
        const data = {
          'comCode': this.showitem[0].exCode,
          'exCode': detailData.deliverySn
        }
        getLogistics(data).then(res => {
          if (res.code === 0) {
            this.info = res.data
          }
        })
      }
    }
  }
}
</script>
<style lang='less' scoped>
.Logistics {
  padding: 96px 0 0;

  .Logistics-info {
    border-bottom: 1px solid #F3F3F3;
    padding: 0 64px;
  }

  .Logistics-info-title {
    color: #333333;
    font-size: 20px;
    font-weight: bold;
    // line-height: 48px;
  }

  .Logistics-info-item {
    color: #666666;
    font-weight: 400;
  }
  .Logistics-list {
    padding: 24px 64px 0;
  }
}
.ant-timeline-item {
  padding: 0 0 50px;

  &:first-child :deep(.ant-timeline-item-tail){
    border-left: 2px dashed #3B6652;
  }
}
:deep(.ant-timeline-item-tail) {
  border-left: 2px dashed #E5E5E5;
}
</style>
