// 数据过滤目标内容
export function filterVal(value, list, Name = 'label', Value = 'value') {
  if (list.some((item) => { return item[Value] === value })) {
    const val = list.filter((item) => {
      return item[Value] === value
    })[0]

    return val[Name]
  }
}

/**
 * 获取uuid
 */
export function getUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
  })
}
