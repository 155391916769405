import request from '@/utils/request'

/**
 * 获取订单
*/
export function toTrade() {
  return request({
    url: '/api/order/web/toTrade',
    method: 'get'
  })
}

/**
 * 下单接口
 * addrId, payType, orderToken, payPrice, note=订单备注
*/
export function submitOrder(params) {
  return request({
    url: `/api/order/web/submitOrder`,
    method: 'post',
    params
  })
}
/**
 * 根据状态获取订单
 * status  (0->待付款 1->进行中 2->已完成 3->售后中 4->待评价)
*/
export function getOrderByStatus(params) {
  return request({
    url: `/api/order/web/getOrderByStatus`,
    method: 'get',
    params
  })
}
/**
 * 根据订单ID获取订单项
 * orderId
*/
export function getOrderItemByOrderId(params) {
  return request({
    url: `/api/order/web/getOrderItemByOrderId`,
    method: 'get',
    params
  })
}
/**
 * 支付
 * orderSn
*/
export function payOrder(params) {
  return request({
    url: `/api/order/web/payOrder`,
    method: 'get',
    params
  })
}
/**
 * 根据订单编号获取订单项
 * orderSn
*/
export function getOrderItemByOrderSn(params) {
  return request({
    url: `/api/order/web/getOrderItemByOrderSn`,
    method: 'get',
    params
  })
}
/**
 * 获取nav信息 (订单详情)
 * orderId
*/
export function getOrderDetailStatus(params) {
  return request({
    url: `/api/order/web/getOrderDetailStatus`,
    method: 'get',
    params
  })
}

/**
 * 获取付款成功的订单及订单项 (订单详情)
 * orderId
*/
export function getOrderPayed(params) {
  return request({
    url: `/api/order/web/getOrderPayed`,
    method: 'get',
    params
  })
}

/**
 * 获取包裹 (订单详情)
 * orderId=?&status=?
*/
export function getParcelDetail(params) {
  return request({
    url: `/api/ware/parcel/getParcelDetail`,
    method: 'get',
    params
  })
}
/**
 * 获取订单详情的信息
 * orderId
*/
export function getOrderMsg(params) {
  return request({
    url: `/api/order/web/getOrderMsg`,
    method: 'get',
    params
  })
}
/**
 * 获取待付款订单的订单信息
 * orderId
*/
export function payingOrder(params) {
  return request({
    url: `/api/order/web/payingOrder`,
    method: 'get',
    params
  })
}

/**
 * 物流信息查询
 * comCode，
 * exCode
*/
export function getLogistics(params) {
  return request({
    url: `/api/order/web/getLogistics`,
    method: 'get',
    params
  })
}
