<template>
  <div class="Breadcrumb">
    <a-breadcrumb>
      <a-breadcrumb-item v-for="(item, index) in routeList" :key="index">
        <router-link :to="item.path">
          {{ item.title }}
        </router-link>
      </a-breadcrumb-item>
      <!-- <a-breadcrumb-item v-if="routeList.length === 2">
        <router-link :to="routeInfo2.path">
          {{ routeInfo2.title }}
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item v-if="routeList.length === 3">
        <router-link :to="routeInfo3.path">
          {{ routeInfo3.title }}
        </router-link>
      </a-breadcrumb-item> -->
    </a-breadcrumb>
    <!-- <a-breadcrumb :routes="routes">
      <template #itemRender="{ route, routes, paths }">
        <span v-if="routes.indexOf(route) === routes.length - 1">
          {{ route.breadcrumbName }}
        </span>
        <router-link v-else :to="`${paths.join('/')}`">
          {{ route.breadcrumbName }}
        </router-link>
      </template>
    </a-breadcrumb> -->
  </div>
</template>

<script>
export default {
  name: 'Breadcrumb',
  props: {
    routeList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    routeInfo1() {
      return this.routeList[0]
    },
    routeInfo2() {
      return this.routeList[1]
    },
    routeInfo3() {
      return this.routeList[2]
    }
  }
}
</script>

<style lang="less">
.Breadcrumb {
  margin-top: 30px;
  padding: 30px 0;
  border-bottom: 1px solid #F3F3F3;
}
</style>
