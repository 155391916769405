<template>
  <div class="OrderDetails container">
    <Breadcrumb :route-list="routerList" />
    <StepBox :list="stepList" @step="clickStepFun" />

    <div class="order-details">
      <div class="details-title">订单详情</div>

      <div class="details-content">
        <div class="details-left">
          <div class="details-card">
            <a-spin :spinning="isLoading">
              <div class="main-order-id">订单编号: {{ orderInfo.orderSn }}</div>
              <div v-if="stepStatus != 2" class="details-item">
                <div v-for="(item, index) in orderGoods" :key="item.id">
                  <div class="details-item-title">
                    <div class="details-item-tt">
                      <span>包裹{{ index + 1 }}: {{ item.parcel.parcelSn || '' }}</span>
                      <span v-if="item.parcel && item.parcel.deliverySn">
                        物流单号: {{ item.parcel.deliverySn }}
                        <span class="db-ml8">物流公司: {{ item.parcel.deliveryCompany }}</span>
                      </span>
                    </div>
                    <div v-if="item.parcel && item.parcel.deliverySn">
                      <span style="color: #3B6652;font-weight: 400;cursor: pointer;" @click="getLogisticsFun(item)">查看物流</span>
                    </div>
                  </div>
                  <div v-for="(gitem, gindex) in item.orderItems" :key="gitem.id" class="goods-item">
                    <div class="goods-item-index">{{ gindex + 1 }}</div>
                    <div class="goods-item-img"><img v-lazy="gitem.skuPic" :alt="gitem.skuId"></div>
                    <div class="goods-item-info">
                      <div class="goods-name db-mb16">{{ gitem.spuName }}</div>
                      <div class="goods-other db-mb16">{{ gitem.skuName }}</div>
                      <div class="goods-price">¥ {{ gitem.skuPrice }} * {{ gitem.skuQuantity }}</div>
                    </div>
                    <div class="goods-item-price">¥ {{ gitem.skuPrice * gitem.skuQuantity }}</div>
                  </div>
                </div>
              </div>
              <div v-if="stepStatus === 2" class="details-item">
                <div v-for="(payitem, index) in orderGoods" :key="payitem.id" class="goods-item">
                  <div class="goods-item-index">{{ index + 1 }}</div>
                  <div class="goods-item-img"><img v-lazy="payitem.skuPic" :alt="payitem.skuId"></div>
                  <div class="goods-item-info">
                    <div class="goods-name db-mb16">{{ payitem.spuName }}</div>
                    <div class="goods-other db-mb16">{{ payitem.skuName }}</div>
                    <div class="goods-price">¥ {{ payitem.skuPrice }} * {{ payitem.skuQuantity }}</div>
                  </div>
                  <div class="goods-item-price">¥ {{ payitem.realAmount }}</div>
                </div>
              </div>
            </a-spin>
          </div>
        </div>
        <div class="details-right">
          <!-- 物流信息 -->
          <div>
            <div class="details-right-title db-mb16">收货信息</div>
            <ul class="Receiving-info">
              <li>
                <UserOutlined class="Receiving-icon" />
                <span class="Receiving-info-label">收货人</span>
                <span class="Receiving-info-value">{{ logisticsInfo.receiverName }}</span>
              </li>
              <li>
                <PhoneOutlined class="Receiving-icon" />
                <span class="Receiving-info-label">联系方式</span>
                <span class="Receiving-info-value">{{ logisticsInfo.receiverPhone }}</span>
              </li>
              <li>
                <EnvironmentOutlined class="Receiving-icon" />
                <span class="Receiving-info-label">收货地址</span>
                <span class="Receiving-info-value">{{ logisticsInfo.receiverAddress }}</span>
              </li>
            </ul>
          </div>
          <!-- 订单信息 -->
          <div>
            <div class="details-right-title db-mb16">订单信息</div>
            <ul class="Receiving-info order-info">
              <li>
                <span class="Receiving-info-label">下单时间</span>
                <span class="Receiving-info-value">{{ orderInfo.modifyTime }}</span>
              </li>
              <li>
                <span class="Receiving-info-label">订单编号</span>
                <span class="Receiving-info-value">{{ orderInfo.orderSn }}</span>
              </li>
              <li>
                <span class="Receiving-info-label">订单金额</span>
                <span class="Receiving-info-value">￥{{ orderInfo.payAmount }}</span>
              </li>
              <li style="margin-bottom: 8px;">
                <span class="Receiving-info-label small-info-label">商品总额</span>
                <span class="Receiving-info-value small-info-value">￥{{ orderInfo.totalAmount }}</span>
              </li>
              <li>
                <span class="Receiving-info-label small-info-label">运费总额</span>
                <span class="Receiving-info-value small-info-value">￥{{ orderInfo.freightAmount }}</span>
              </li>
            </ul>
          </div>
          <!-- 支付信息 -->
          <div>
            <div class="details-right-title db-mb16">订单信息</div>
            <ul class="Receiving-info">
              <li>
                <span class="Receiving-info-label">下单时间</span>
                <span class="Receiving-info-value">{{ payInfo.paymentTime }}</span>
              </li>
              <li>
                <span class="Receiving-info-label">支付方式</span>
                <span class="Receiving-info-value">{{ filterVal(payInfo.payType, payType, 'label', 'value') }}</span>
              </li>
            </ul>
          </div>

          <div class="details-btn-box">
            <button class="details-btn" @click="toInvoicing">申请开票</button>
          </div>
        </div>
      </div>

    </div>
    <logistics ref="detaillogistics" :drawer-ligistics="iShowLigistics" @closeDrawer="closeFun" />
  </div>
</template>

<script>
import { UserOutlined, PhoneOutlined, EnvironmentOutlined } from '@ant-design/icons-vue'
import Breadcrumb from '@/components/Breadcrumb'
import StepBox from '@/views/user/components/stepBox'
import logistics from '@/components/logistics'
import { getOrderDetailStatus, getOrderPayed, getParcelDetail, getOrderMsg } from '@/api/order'
import { filterVal } from '@/utils'
import { mapGetters } from 'vuex'
export default {
  name: 'OrderDetails',
  components: {
    Breadcrumb,
    StepBox,
    UserOutlined,
    PhoneOutlined,
    EnvironmentOutlined,
    logistics
  },
  data() {
    return {
      routerList: [
        { path: '/user/userInfo', title: '个人中心' },
        { path: '/order/orderDetails', title: '订单详情' }
      ],
      orderId: '',
      stepList: [],
      orderInfo: {},
      logisticsInfo: {},
      payInfo: {},
      payType: [
        { label: '微信支付', value: 0 },
        { label: '支付宝支付', value: 1 },
        { label: '银联支付', value: 2 },
        { label: '线下对公支付', value: 3 }
      ],
      stepStatus: 1,
      orderGoods: [],
      iShowLigistics: false,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'loading/isLoading'
    })
  },
  created() {
    this.orderId = this.$route.query.id
    this.getStepInfo(this.orderId)
    this.getOrderMsgFun(this.orderId)
  },
  methods: {
    // 查看物流信息
    getLogisticsFun(data) {
      console.log(data)
      this.iShowLigistics = !this.iShowLigistics
      this.$refs.detaillogistics.init(data.parcel)
    },
    closeFun() {
      this.iShowLigistics = false
    },
    clickStepFun(val) {
      this.stepStatus = val.status
      if (val.status === 1) {
        // console.log('val')
      } else if (val.status === 2) {
        this.getPayOrderInfoFun(this.orderId)
      } else {
        this.getOtherOrderInfoFun(val.status)
      }
    },
    getStepInfo(id) { // 获取步骤信息
      const data = {
        orderId: id
      }
      getOrderDetailStatus(data).then(res => {
        this.stepList = res.data
        const list = this.stepList.filter(item => item.hit === 1)
        this.stepStatus = list[list.length - 1].status
        if (this.stepStatus === 2) {
          this.getPayOrderInfoFun(this.orderId)
        } else {
          this.getOtherOrderInfoFun(this.stepStatus)
        }
      })
    },
    getPayOrderInfoFun(id) { // 获取支付项订单信息
      const data = {
        orderId: id
      }
      getOrderPayed(data).then(res => {
        this.orderGoods = res.data.items
      })
    },
    getOtherOrderInfoFun(status) { // 获取其他项订单信息
      const data = {
        orderId: this.orderId,
        status: status
      }
      getParcelDetail(data).then(res => {
        this.orderGoods = res.data
      })
    },
    getOrderMsgFun(id) { // 获取订单信息
      const data = {
        orderId: id
      }
      getOrderMsg(data).then(res => {
        this.orderInfo = res.data.order
        this.payInfo = res.data.pay
        this.logisticsInfo = res.data.addr
      })
    },
    toInvoicing() {
      this.$router.push({ path: '/user/userInfo', query: { comtype: 'InvoiceCenter' }})
    },
    filterVal
  }
}
</script>
<style lang='less' scoped>
.OrderDetails {

  .order-details {
    margin-top: 16px;
    margin-bottom: 40px;

    .details-title {
      color: #333333;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 40px;
    }
  }

  .details-content {
    display: flex;

    .details-left {
      min-height: 620px;
      padding-right: 49px;
      border-right: 1px solid #F3F3F3;

      .main-order-id {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 26px;
        padding: 0 48px;
      }

      .details-card {
        width: 825px;
        min-height: 298px;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);

        padding: 40px 0;
      }
      .details-item {
        border-bottom: 1px solid #F3F3F3;
        .details-item-title {
          color: #333333;
          font-weight: bold;
          padding: 0 48px;
          display: flex;
          justify-content: space-between;
          // flex-direction: column;
          .details-item-tt {
            display: flex;
            flex-direction: column;
          }
        }
      }
      .goods-item {
        display: flex;
        align-items: center;
        padding: 26px 48px;
        height: 208px;

        .goods-item-index {
          color: #333333;
          font-size: 20px;
          font-weight: bold;
        }
        .goods-item-img {
          width: 157px;
          height: 157px;
          box-sizing: border-box;
          margin-left: 70px;
          margin-right: 9px;

          &>img {
            width: 100%;
            height: 100%;
          }
        }
        .goods-item-info {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          flex: 1;

          .goods-name {
            color: #333333;
            font-size: 16px;
            font-weight: bold;
          }
          .goods-other .goods-price {
            color: #666;
            font-weight: 400;
          }
        }
        .goods-item-price {
          color: #333333;
          font-size: 20px;
          font-weight: bold;
        }
      }
    }

    .details-right {
      padding-left: 48px;

      .details-right-title {
        color: #333333;
        font-weight: bold;
        font-size: 16px;
      }
      .Receiving-info {
        margin-bottom: 32px;

        & > li {
          margin-bottom: 16px;
          display: flex;
          align-items: flex-start;

          .Receiving-icon {
            font-size: 18px;
            margin-right: 8px;
          }
          .Receiving-info-label {
            width: 56px;
            height: 20px;
            text-align: justify;
            margin-right: 24px;

            &::after {
              content:'';
              width: 100%;
              display: inline-block;
              overflow: hidden;
              height: 0;
            }
          }
          .Receiving-info-value {
            width: 210px;
            margin-right: 20px;
          }

        }
      }
      .order-info {
        & > li {
          .Receiving-info-label {
            width: 70px;
          }
          .small-info-label {
            color: #999999;
            font-size: 12px;
            font-weight: 400;
            text-align: right;
          }
          .small-info-value {
            color: #999999;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }

      .details-btn-box {

        .details-btn {
          width: 180px;
          height: 56px;
          background: #3B6652;
          border-radius: 32px;
          color: #fff;
          font-size: 16px;
          font-weight: 400;
          text-align: center;
          line-height: 56px;
          border: 0;
          padding: 0;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
